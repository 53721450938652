import React, { useState, useContext } from "react";
import { withRouter, Link } from "react-router-dom";

import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";

import UserContext from "../../context/userContext";

import css from "./style.module.css";

import Spinner from "../General/Spinner";
import logo from "../../assets/images/logo.png";

const Forget = (props) => {
  const userCtx = useContext(UserContext);
  const [verifyCode, setVerifyCode] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [mongoliaCode, setMongoliaCode] = useState("+976");
  const [getPin, setGetPin] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [captchaLoaded, setCaptchaLoaded] = useState(false);
  const [confirmationResult, setConfirmationResult] = useState("");

  const [newPassword, setNewPassword] = useState(false);
  const [fieldValidationErrors, setFieldValidationErrors] = useState({});
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState({});
  const [passwordHidden, setPasswordHidden] = useState(false);

  const [error, setError] = useState(false);
  const [pinError, setPinError] = useState(false);
  const handleSubmit = (e) => {
    const newPhoneNumber = `${mongoliaCode + phoneNumber}`;

    if (phoneNumber.length !== 8) {
      setError(true);
    } else {
      setSubmitted(true);
      setLoading(true);
      const appVerifier = new firebase.auth.RecaptchaVerifier(
        "recaptcha-container",
        {
          size: "normal",
          callback: (response) => {
            // reCAPTCHA solved, allow signInWithPhoneNumber.
            // ...
            setCaptchaLoaded(true);
            sendMessage(newPhoneNumber, appVerifier);
          },
          "expired-callback": function () {
            alert("expired");
            // Response expired. Ask user to solve reCAPTCHA again.
            // ...
          },
        }
      );
      appVerifier.render().then((widgetId) => {
        setCaptchaLoaded(false);
      });
    }
  };
  const sendMessage = (phoneNumber, appVerifier) => {
    firebase
      .auth()
      .signInWithPhoneNumber(phoneNumber, appVerifier)
      .then((confirmationResult) => {
        // SMS sent. Prompt user to type the code from the message, then sign the
        // user in with confirmationResult.confirm(code).
        window.confirmationResult = confirmationResult;
        //verifyCode
        setGetPin(true);
        setLoading(false);
        setConfirmationResult(confirmationResult);
      })
      .catch((e) => {
        alert("Captcha буруу оруулсан байна.");
        setGetPin(false);
        setLoading(false);

        // this.setState({ serverError: true, messageError: e });
        // Error; SMS not sent
        // ...
      });
  };
  //pin code batalgaajuulah
  const handleSubmitGetPin = (e) => {
    if (getPin > 111111) {
      setLoading(true);
      setPinError(false);
      confirmationResult
        .confirm(getPin)
        .then((result) => {
          // User signed in successfully.
          setLoading(false);
          setNewPassword(true);
          // ...
        })
        .catch((error) => {
          setPinError(true);
          setLoading(false);
        });
    } else {
      setPinError(true);
    }
  };
  //shine nuuts ug uusgeh
  const handleNewPassword = (e) => {
    e.preventDefault();
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    let passwordValid = "";
    let passwordConfirmValid = "";
    let phonenumberValid = "";
    switch (name) {
      case "getPin":
        setGetPin(value);
        break;
      case "password":
        passwordValid = value.length >= 6 && value;
        fieldValidationErrors.password = passwordValid
          ? ""
          : " Багадаа 6 н тэмдэгт";
        break;
      case "passwordConfirm":
        passwordConfirmValid = password === value && value;
        fieldValidationErrors.passwordConfirm = passwordConfirmValid
          ? ""
          : " Буруу байна";
        break;
      case "phoneNumber":
        phonenumberValid = value.length === 8 && value;
        fieldValidationErrors.phonenumber = phonenumberValid ? "" : "8 оронтой";
        break;
    }

    if (passwordValid) setPassword(passwordValid);
    if (passwordConfirmValid) setPasswordConfirm(passwordConfirmValid);
    if (phonenumberValid) setPhoneNumber(phonenumberValid);
    setFieldValidationErrors(fieldValidationErrors);

    setError(false);
  };

  const showHide = (fieldName) => {
    if (fieldName === "password") setPasswordHidden(!passwordHidden);
  };
  console.log(fieldValidationErrors);
  return (
    <div className={css.Main}>
      {loading && (
        <div className={css.Loading}>
          <Spinner />
        </div>
      )}

      <div className={css.Logo}>
        <img src={logo} alt="KONICA MINOLTA" />
      </div>
      {!getPin ? (
        <div className={css.MainRegister}>
          {!captchaLoaded && (
            <div className={css.Tailbar}>
              Та өөрийн утасны дугаараа оруулна уу?
            </div>
          )}

          <div className={css.Phone}>
            <div className={css.field}>
              {!captchaLoaded ? (
                <input
                  type="number"
                  name="phoneNumber"
                  id="phoneNumber"
                  placeholder=" "
                  onChange={handleChange}
                  className={phoneNumber ? css.input : css}
                />
              ) : (
                <div>{phoneNumber}</div>
              )}
              <label
                className={
                  submitted ? (phoneNumber ? css.label : css.error) : css.label
                }
                htmlFor="phoneNumber"
              >
                <div className={error ? css.error : null}>
                  {error ? "Утасны дугаар буруу байна " : "Утасны дугаар "}
                  <span style={{ color: "red" }}>
                    {fieldValidationErrors.phonenumber}
                  </span>
                </div>
              </label>
            </div>
          </div>
          {!error && (
            <div id="recaptcha-container" className={css.captcha}></div>
          )}
          <div className={css.BackButton}>
            {captchaLoaded && <Link to="/login/">БУЦАХ</Link>}
          </div>
          {!captchaLoaded && (
            <div className={css.LoginButton}>
              <button onClick={handleSubmit}>ҮРГЭЛЖЛҮҮЛЭХ</button>
            </div>
          )}
        </div>
      ) : (
        <div>
          {!newPassword ? (
            <div className={css.MainRegister}>
              <div className={css.Tailbar}>
                Таны утсан дээр ирэх КОД-ыг оруулна уу?
              </div>

              <div className={css.Phone}>
                <div className={css.field}>
                  <input
                    type="password"
                    name="getPin"
                    id="getPin"
                    placeholder=" "
                    onChange={handleChange}
                    className={getPin ? css.input : css}
                  />
                  <label
                    className={
                      submitted ? (getPin ? css.label : css.error) : css.label
                    }
                    htmlFor="getPin"
                  >
                    <div className={pinError ? css.error : null}>
                      {pinError ? "ПИН КОД БУРУУ БАЙНА" : "ПИН КОД ОРУУЛАХ"}
                    </div>
                  </label>
                </div>
              </div>
              <div className={css.BackButton}>
                {loading && <Link to="/login">БУЦАХ</Link>}
              </div>

              <div className={css.LoginButton}>
                <button onClick={handleSubmitGetPin}>ҮРГЭЛЖЛҮҮЛЭХ</button>
              </div>
            </div>
          ) : (
            <div className={css.MainRegister}>
              <div className={css.Tailbar}>Нууц үг шинээр үүсгэх</div>

              <div className={css.Phone}>
                <div className={css.field}>
                  <input type="hidden" />
                  <input
                    type={!passwordHidden ? "password" : "text"}
                    name="password"
                    id="password"
                    onChange={handleChange}
                    className={password ? css.input : null}
                  />
                  <span
                    className={css.passwordShow}
                    onClick={() => showHide("password")}
                  >
                    {!passwordHidden ? "харах" : "нуух"}
                  </span>
                  <label
                    className={
                      submitted ? (password ? css.label : css.error) : css.label
                    }
                    htmlFor="password"
                  >
                    <div>
                      НУУЦ ҮГ
                      <span style={{ color: "red" }}>
                        {fieldValidationErrors.password &&
                          fieldValidationErrors.password}
                      </span>
                    </div>
                  </label>
                </div>
              </div>

              <div className={css.field}>
                <input
                  type={!passwordHidden ? "password" : "text"}
                  name="passwordConfirm"
                  id="passwordConfirm"
                  placeholder=" "
                  onChange={handleChange}
                  className={passwordConfirm ? css.input : null}
                />
                <span
                  className={css.passwordShow}
                  onClick={() => showHide("password")}
                >
                  {!passwordHidden ? "харах" : "нуух"}
                </span>
                <label
                  className={
                    submitted
                      ? passwordConfirm
                        ? css.label
                        : css.error
                      : css.label
                  }
                  htmlFor="passwordConfirm"
                >
                  <div
                    className={props.type === "alert-danger" ? css.error : null}
                  >
                    {props.type === "alert-danger"
                      ? "НУУЦ ҮГ БУРУУ БАЙНА"
                      : "НУУЦ ҮГ ДАХИН ОРУУЛНА УУ:"}
                    <span style={{ color: "red" }}>
                      {fieldValidationErrors.passwordConfirm &&
                        fieldValidationErrors.passwordConfirm}
                    </span>
                  </div>
                </label>
              </div>

              <div className={css.LoginButton}>
                <button onClick={handleNewPassword}>НЭВТРЭХ</button>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default withRouter(Forget);
