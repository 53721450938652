const initialState = {
  PhotosList: {},
  thumbLinkList: {},
  photos: {
    "3x4": 0,
    "9x12": 0,
    "10x15": 0,
    "13x18": 0,
    "16x22": 0,
    "20x25": 0,
    "20x30": 0
  },
  PHOTO_PRICES: {
    "3x4": 1000,
    "9x12": 400,
    "10x15": 400,
    "13x18": 800,
    "16x22": 1000,
    "20x25": 3000,
    "20x30": 3500
  },
  defaultSize: "10x15",
  phoneNumber: "99999999",
  totalPrice: 0
};

// const PhotosList = {};
// const thumbLinkList = {};
export function photoReducer(state = initialState, action) {
  switch (action.type) {
    case "ADD_PHOTOS":
      return {
        PhotosList: action.photo,
        thumbLinkList: action.thumb,
        photos: {
          ...state.photos,
          [state.defaultSize]: state.photos[state.defaultSize] + 1
        },
        PHOTO_PRICES: state.PHOTO_PRICES,
        totalPrice: state.totalPrice + state.PHOTO_PRICES[state.defaultSize],
        defaultSize: state.defaultSize,
        phoneNumber: state.phoneNumber
      };

    case "ADD_PHOTOS_PIECE":
      let newPhotos = { ...state.PhotosList };
      newPhotos[action.photoIndex][action.photoSize]
        ? newPhotos[action.photoIndex][action.photoSize]++
        : (newPhotos[action.photoIndex][action.photoSize] = 1);

      return {
        PhotosList: newPhotos,
        thumbLinkList: state.thumbLinkList,
        photos: {
          ...state.photos,
          [action.photoSize]: state.photos[action.photoSize] + 1
        },
        PHOTO_PRICES: state.PHOTO_PRICES,
        defaultSize: state.defaultSize,
        phoneNumber: state.phoneNumber,
        totalPrice: state.totalPrice + state.PHOTO_PRICES[action.photoSize]
      };

    case "REMOVE_PHOTOS_PIECE":
      let newRemovePhotos = { ...state.PhotosList };
      let newPieces = { ...state.photos };

      if (newRemovePhotos[action.photoIndex][action.photoSize]) {
        newRemovePhotos[action.photoIndex][action.photoSize]--;

        if (newRemovePhotos[action.photoIndex][action.photoSize] >= 0) {
          newPieces[action.photoSize]--;
          state.totalPrice =
            state.totalPrice - state.PHOTO_PRICES[action.photoSize];
        }
      } else {
        newRemovePhotos[action.photoIndex][action.photoSize] = 0;
      }

      if (newRemovePhotos[action.photoIndex][action.photoSize] === 0) {
        //console.log(newPhotosList[action.photoIndex][1]);
        delete newRemovePhotos[action.photoIndex][action.photoSize];
      }

      return {
        PhotosList: newRemovePhotos,
        thumbLinkList: state.thumbLinkList,
        photos: newPieces,
        PHOTO_PRICES: state.PHOTO_PRICES,
        totalPrice: state.totalPrice,
        phoneNumber: state.phoneNumber,
        defaultSize: state.defaultSize
      };
    case "CHANGE_SIZE":
      return {
        PhotosList: state.PhotosList,
        thumbLinkList: state.thumbLinkList,
        photos: state.photos,
        PHOTO_PRICES: state.PHOTO_PRICES,
        totalPrice: state.totalPrice,
        phoneNumber: state.phoneNumber,
        defaultSize: action.photoSize
      };

    case "PHOTOS_CLEAR":
      return initialState;

    default:
      return state;
  }
}
