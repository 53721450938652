import axios from "../../axios";

export const newsService = {
  allNews,
  getNews,
  addNews,
  delNews,
  editNews,
};
function allNews(categoryId) {
  return axios.get(`/api/posts/${categoryId}/`).then((result) => {
    return result.data;
  });
}
function getNews(newsId) {
  return axios.get(`/news/view/${newsId}`).then((result) => {
    return result.data;
  });
}

function addNews(cid, title, newdate, content, picture) {
  const fieldData = {
    cid,
    title,
    date: newdate,
    content,
    picture,
  };
  return axios.post(`/news`, fieldData).then((result) => {
    return result.data;
  });
}
function editNews(id, title, newsdate, content, picture) {
  const fieldData = {
    id,
    title,
    date: newsdate,
    content,
    picture,
  };
  return axios.patch(`/news`, fieldData).then((result) => {
    return result.data;
  });
}
function delNews(newsId) {
  return axios.delete(`/news/${newsId}`).then((result) => {
    return result.data;
  });
}
