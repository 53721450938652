export const newsConstants = {
  EDITNEWS_REQUEST: "EDIT_NEWS_REQUEST",
  EDITNEWS_SUCCESS: "EDIT_NEWS_SUCCESS",
  EDITNEWS_FAILURE: "EDIT_NEWS_FAILURE",

  ADDNEWS_REQUEST: "ADD_NEWS_REQUEST",
  ADDNEWS_SUCCESS: "ADD_NEWS_SUCCESS",
  ADDNEWS_FAILURE: "ADD_NEWS_FAILURE",

  GETALL_REQUEST: "NEWS_GETALL_REQUEST",
  GETALL_SUCCESS: "NEWS_GETALL_SUCCESS",
  GETALL_FAILURE: "NEWS_GETALL_FAILURE",

  DELETE_REQUEST: "NEWS_DELETE_REQUEST",
  DELETE_SUCCESS: "NEWS_DELETE_SUCCESS",
  DELETE_FAILURE: "NEWS_DELETE_FAILURE"
};
