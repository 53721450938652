import { userConstants } from "../_constants";

export function registration(state = {}, action) {
  switch (action.type) {
    case userConstants.REGISTER_REQUEST:
      return { registerLoadining: true };
    case userConstants.REGISTER_SUCCESS:
      return { registerLoadining: false, message: action.user };
    case userConstants.REGISTER_FAILURE:
      return { error: action.error.message, errorCode: action.error.success };
    default:
    case userConstants.REGISTER_CLEAR:
      return {};
      break;

      return state;
  }
}
