import React, { useState, Suspense } from "react";
import { Switch, Route } from "react-router-dom";
import css from "./style.module.css";

//pages
import LoginPage from "../Login";
//components
import Toolbar from "../../components/Toolbar";
import SideBar from "../../components/MenuBuilder/SideBar";
import Terms from "../../components/Terms/ourTerms";
import Spinner from "../../components/General/Spinner";
//context
import { PhotoStore } from "../../context/photoContext";
import { OrderStore } from "../../context/orderContext";
import { NewsStore } from "../../context/newsContext";
import { MenuStore } from "../../context/menuContext";

import Footer from "../../components/Footer";

const PhotoOrder = React.lazy(() => {
  return import("../PhotoOrder");
});
const Orders = React.lazy(() => {
  return import("../Orders");
});
const News = React.lazy(() => {
  return import("../News");
});
const Cart = React.lazy(() => {
  return import("../Cart");
});
const Contact = React.lazy(() => {
  return import("../Contact");
});
const NotFoundPage = React.lazy(() => {
  return import("../NotFoundPage");
});

const App = (props) => {
  const [showSidebar, setShowSidebar] = useState(false);

  const toggleSideBar = () => {
    setShowSidebar((prevShowSidebar) => !prevShowSidebar);
  };

  return (
    <div className={css.Main}>
      <div>
        <div>
          <MenuStore>
            <Toolbar toggleSideBar={toggleSideBar} />
            <SideBar showSidebar={showSidebar} toggleSideBar={toggleSideBar} />
          </MenuStore>
        </div>
        
        <PhotoStore>
          <Suspense
            fallback={
              <div className={css.Loading}>
                <Spinner />
                <div>Түр хүлээнэ үү... </div>
                Таны хүсэлтийг боловсруулж байна.                 
              </div>
            }
          >
            <Switch>              
                <OrderStore>                               
                  <Route exact path="/" component={PhotoOrder} />
                  <Route path="/error" component={NotFoundPage} />     
                  <Route path="/cart" exact component={Cart}/>
                  <Route path="/cart/orders" component={Orders} />
                  <Route path="/photos" component={PhotoOrder} /> 
                  <Route path="/login" component={LoginPage} />
                  <NewsStore>
                    <Route path="/news" component={News} />
                  </NewsStore>
                  <Route path="/terms" component={Terms} />
                  <Route path="/contact" component={Contact} />
                </OrderStore>
              
            </Switch>
          </Suspense>
        </PhotoStore>
   
      </div>

      <Footer />
    </div>
  );
};

export default App;
