import { newsConstants } from "../_constants";

export function news(state = {}, action) {
  switch (action.type) {
    //GET NEWS
    case newsConstants.GETALL_REQUEST:
      return {
        loading: true
      };
    case newsConstants.GETALL_SUCCESS:
      return {
        loading: false,
        newsList: action.newsList
      };
    case newsConstants.GETALL_FAILURE:
      return {
        error: action.error
      };
    //ADD NEWS
    case newsConstants.ADDNEWS_REQUEST:
      return {
        loading: true
      };
    case newsConstants.ADDNEWS_SUCCESS:
      return {
        loading: false,
        newsList: action.newsList
      };
    case newsConstants.ADDNEWS_FAILURE:
      return {
        error: action.error
      };
    // EDIT NEWS
    case newsConstants.EDITNEWS_REQUEST:
      return {
        loading: true
      };
    case newsConstants.EDITNEWS_SUCCESS:
      return {
        loading: false,
        newsList: action.newsList
      };
    case newsConstants.EDITNEWS_FAILURE:
      return {
        error: action.error
      };
    default:
      return state;
  }
}
