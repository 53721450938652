import React, { useState } from "react";
import { newsService } from "./news.service";
import { useParams, useHistory } from "react-router-dom";
const NewsContext = React.createContext();

const initialState = {
  data: "",
  getDatabaseNews:  {"download": false, "errorMsg": "", "statusCode": ""},
};
export const NewsStore = (props) => {
  const [state, setState] = useState(initialState);
  let history = useHistory();
  let params = useParams();

  //buh medee
  const allNews = (categoryId) => {
    const download = {}
    newsService
      .allNews(categoryId)
      .then((result) => {
        download['download'] = true;
        if (result.length > 0) {     
        return  setState({
            ...state,
            getDatabaseNews: download,
            data: result,
          });
        }else {
          return  setState({
            ...state,
            getDatabaseNews: download,
            data: result,
          });
        }
      })
      .catch((e) => {
        download['download'] = false;
        download['errorMsg'] = "Сервэрээс өгөгдөл татахад алдаа гарлаа."
        download['statusCode'] = e.response.status;
        setState({
          ...state,
          getDatabaseNews: download,
        });
        history.push("/error");
      });
  };

  const getNews = (newsId) => {
    setState({ ...state, loading: true });
    newsService
      .getNews(newsId)
      .then((result) => {
        if (result.success === 1) {
          setState({ ...state, loading: false, getNews: result.data });
        } else {
          setState({
            ...state,
            loading: false,
            error: result.message,
          });
        }
      })
      .catch((error) => {
        setState({ ...state, loading: false, error });
      });
  };

  const addNews = (cid, title, newdate, content, picture) => {
    setState({ ...state, loading: true });
    newsService
      .addNews(cid, title, newdate, content, picture)
      .then((result) => {
        if (result.success === 1) {
          setState({ ...state, loading: false, getNews: result.data });
          history.goBack();
        } else {
          setState({
            ...state,
            loading: false,
            error: result.message,
          });
        }
      })
      .catch((error) => {
        setState({ ...state, loading: false, error });
      });
  };
  const deleteNews = (id) => {
    setState({ ...state, loading: true });
    newsService
      .delNews(id)
      .then((result) => {
        if (result.success === 1) {
          setState({
            ...state,
            loading: false,
            message: state.message.filter((news) => news.news_id !== id),
          });
        } else {
          setState({
            ...state,
            loading: false,
            error: result.message,
          });
        }
      })
      .catch((error) => {
        setState({ ...state, loading: false, error });
      });
  };

  const editNews = (newsId, title, newsDate, content, picture) => {
    setState({ ...state, loading: true });
    newsService
      .editNews(newsId, title, newsDate, content, picture)
      .then((result) => {
        if (result.success === 1) {
          history.goBack();
          setState({ ...state, loading: false, getNews: result.message });
        } else {
          setState({
            ...state,
            loading: false,
            error: result.message,
          });
        }
      })
      .catch((error) => {
        setState({ ...state, loading: false, error });
      });
  };

  return (
    <NewsContext.Provider
      value={{
        state,
        allNews,
        addNews,
        getNews,
        deleteNews,
        editNews,
      }}
    >
      {props.children}
    </NewsContext.Provider>
  );
};

export default NewsContext;
