import React from "react";
import LogoImage from "../../assets/images/logo.png";
import css from "./style.module.css";
import { Link } from "react-router-dom";
const Logo = () => (
  <div className={css.Logo}>
    <Link to="/">
      <img src={LogoImage} alt={LogoImage} />
    </Link>
  </div>
);

export default Logo;
