import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import axios from "../../axios";
const menuContext = React.createContext();

const initialState = {
  getDatabaseMenu: {"downloadMenu": false, "errorMsg": "", "statusCode": ""},
  menuList: [
                {
                    "id": '',
                    "name": '',
                    "slug": '',
                    "parent_id": '',
                    "icon": '',
                    "category_id": '',
                    "path": '',
                    "status": '',
                    "order": '',
                    "created_at": '0',
                    "updated_at": ''
                }
            ]
};
export const MenuStore = (props) => {
  const [state, setState] = useState(initialState);
  
  let history = useHistory();
  
  //Серверээс меню татаж байна.
  const getMenu = ()=>{
   const download = {}
     return axios.get(`/api/menus/`).then((result) => {
        download['downloadMenu'] = true;
        if (result.data.length > 0) {        
          return setState({
            ...state,
            menuList: result.data,
            getDatabaseMenu: download,
          });
        }
      }).catch((e) =>{
        download['downloadMenu'] = false;
        download['errorMsg'] = "Сервэрээс өгөгдөл татахад алдаа гарлаа."
        //download['statusCode'] = e.response.status;
        setState({
          ...state,
          getDatabaseMenu: download,
        });
        history.push("/error");
      });
  }
  
  return (
    <menuContext.Provider
      value={{
        state,
        getMenu,
      }}
    >
      {props.children}
    </menuContext.Provider>
  );
};

export default menuContext;
