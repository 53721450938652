import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";

import { withRouter, Redirect } from "react-router-dom";

import UserContext from "../../context/userContext";
import Spinner from "../../components/General/Spinner";

import css from "./style.module.css";

import logo from "../../assets/images/logo.png";
// import apple from "../../assets/images/apple.png";
// import android from "../../assets/images/android.png";

const Login = (props) => {
  const ctx = useContext(UserContext);

  const [phonenumber, setPhonenumber] = useState("");
  const [password, setPassword] = useState("");
  const [submitted, setSubmitted] = useState(false);

  const [fieldValidationErrors, setFieldValidationErrors] = useState({});

  //login hiih heseg
  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitted(true);

    const user = {
      username: phonenumber,
      password,
    };
    if (phonenumber && password) {
      ctx.loginUser(user);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "phonenumber")
      setPhonenumber(() => validateField(name, value));
    if (name === "password") setPassword(value);
  };

  const validateField = (fieldName, value) => {
    let phonenumberValid = "";
    switch (fieldName) {
      case "phonenumber":
        phonenumberValid = value.length === 8 && value;
        fieldValidationErrors.phonenumber = phonenumberValid ? "" : "8 оронтой";

        break;
    }

    if (phonenumberValid) setPhonenumber(phonenumberValid);
    setFieldValidationErrors(fieldValidationErrors);
  };

  return (
    <div className={css.Main}>
      {ctx.state.token && <Redirect to="/login/welcome" />}
      {ctx.state.loading ? (
        <div>
          <Spinner />
        </div>
      ) : (
        <div className={css.Main}>
          <div className={css.Logo}>
            <img src={logo} alt="KONICA MINOLTA" />
          </div>
          <div style={{ color: "red" }}>
            {ctx.state.error && ctx.state.error.data.message}
          </div>
          <div>
            <form name="form" onSubmit={handleSubmit}>
              <div className={css.Phone}>
                <div className={css.field}>
                  <input
                    type="number"
                    name="phonenumber"
                    id="phonenumber"
                    placeholder=" "
                    onChange={handleChange}
                    className={phonenumber ? css.input : null}
                  />
                  <label
                    className={
                      submitted
                        ? phonenumber
                          ? css.label
                          : css.error
                        : css.label
                    }
                    htmlFor="phonenumber"
                  >
                    <div
                      className={ctx.state.error === "asd" ? css.error : null}
                    >
                      {ctx.state.error === "asd"
                        ? "УТАСНЫ ДУГААР БУРУУ БАЙНА "
                        : "Утасны дугаар: "}
                      <span style={{ color: "red" }}>
                        {fieldValidationErrors.phonenumber}
                      </span>
                    </div>
                  </label>
                </div>
                <div className={css.field}>
                  <input
                    type="password"
                    name="password"
                    id="password"
                    placeholder=" "
                    onChange={handleChange}
                  />
                  <Link className={css.passwordShow} to="/login/forget">
                    мартсан
                  </Link>
                  <label
                    htmlFor="password"
                    className={
                      submitted ? (password ? css.label : css.error) : css.label
                    }
                  >
                    <div
                      className={ctx.state.error === "asd" ? css.error : null}
                    >
                      {ctx.state.error === "asd"
                        ? "НУУЦ ҮГ БУРУУ БАЙНА"
                        : "НУУЦ ҮГ"}
                    </div>
                  </label>
                </div>
              </div>
              <div className={css.PinCode}>
                <Link to="/login/register" style={{ color: "blue" }}>
                  ШИНЭЭР БҮРТГҮҮЛЭХ
                </Link>
              </div>

              <div className={css.LoginButton}>
                <button>НЭВТРЭХ</button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default withRouter(Login);
