import React, { useState } from "react";

import { userService } from "./user.service";
import axios from "../../axios";
const UserContext = React.createContext();

const token = localStorage.getItem("token");
const username = localStorage.getItem("username");
const usertype = localStorage.getItem("usertype");

const initialState = {
  loading: false,
  error: null,
  errorCode: null,
  token: token,
  username: username,
  usertype: usertype,
  //expireDate: null,
};

const registerState = {
  registerLoading: false,
  message: null,
  error: null,
};

export const UserStore = (props) => {
  const [state, setState] = useState(initialState);
  const [register, setRegister] = useState(registerState);
  //hereglegch burtgeh
  const userRegister = (users) => {
    setRegister({ ...register, registerLoading: true });

    userService
      .register(users)
      .then((result) => {
        if (result.success === 4) {
          setRegister({ ...register, registerLoading: false, message: result });
          localStorage.setItem("username", users.phonenumber);
          setState({ username: users.phonenumber });
        } else {
          setRegister({
            ...register,
            registerLoading: false,
            error: result.message,
          });
          localStorage.setItem("username", users.phonenumber);
          setState({ username: users.phonenumber });
        }
      })
      .catch((error) => {
        setRegister({ ...register, registerLoading: false, error });
      });
  };

  const loginUser = (userData) => {
    setState({ ...state, loading: true });

    return axios
      .post("/users/login", userData)
      .then((result) => {
        if (result.data.success === 1) {
          const token = result.data.token;
          const username = result.data.user.phonenumber;
          const usertype = result.data.user.usertype;
          // const expiresIn = result.data.expiresIn;
          // const expireDate = new Date(new Date().getTime() + expiresIn * 1000);
          // const refreshToken = result.data.refreshToken;

          localStorage.setItem("token", token);
          localStorage.setItem("username", username);
          localStorage.setItem("usertype", usertype);
          setState({
            ...state,

            loading: false,
            token: token,
            username: username,
            usertype: usertype,
          });
        } else {
          setState({
            ...state,

            loading: false,
            token: null,
            username: null,
            error: result,
          });
        }
      })
      .catch((error) => {
        setState({
          ...state,

          loading: false,
          token: null,
          username: null,
          error: error,
        });
      });
  };

  const forget = (username, code) => {
    console.log(code);
  };

  const logout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("username");
    localStorage.removeItem("usertype");
    setState({
      ...state,
      loading: false,
      error: null,
      errorCode: null,
      token: null,
      username: null,
      usertype: null,
    });
  };

  return (
    <UserContext.Provider
      value={{ state, loginUser, register, logout, userRegister, forget }}
    >
      {props.children}
    </UserContext.Provider>
  );
};

export default UserContext;
