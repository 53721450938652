import React, { useContext } from "react";

import { withRouter, Redirect } from "react-router-dom";

import UserContext from "../../context/userContext";
import css from "./style.module.css";
import { userActions } from "../../redux/_actions";
import logo from "../../assets/images/logo.png";

const LogOut = (props) => {
  const ctx = useContext(UserContext);

  const handleSubmit = (e) => {
    e.preventDefault();
    ctx.logout();
  };

  return (
    <div className={css.Main}>
      {!ctx.state.token && <Redirect to="/login" />}
      <div className={css.Logo}>
        <img src={logo} alt="KONICA MINOLTA" />
      </div>
      <div>Тавтай морил</div>
      <div>{ctx.state.username}</div>
      <div>
        <form name="form" onSubmit={handleSubmit}>
          <div className={css.LoginButton}>
            <button>СИСТЕМЭЭС ГАРАХ</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default withRouter(LogOut);
