import React from "react";
import { Route } from "react-router-dom";

import css from "./style.module.css";
import Login from "../../components/Login/login";
import LogOut from "../../components/Login/logOut";
import Register from "../../components/Login/register";
import Forget from "../../components/Login/forget";

class LoginPage extends React.Component {
  render() {
    return (
      <div className={css.App}>
        <div className={css.login}>
          <div className={css.container}>
            <Route path="/login/" exact component={Login} />
            <Route path="/login/register" component={Register} />
            <Route path="/login/forget" component={Forget} />
            <Route path="/login/welcome" component={LogOut} />
          </div>
        </div>
      </div>
    );
  }
}

export default LoginPage;
