import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import "./index.css";
import App from "./pages/App";
import * as serviceWorker from "./serviceWorker";
//context
import { UserStore } from "./context/userContext";

//import Firebase, { FirebaseContext } from "./components/Firebase";

ReactDOM.render(
  <Router>
    <UserStore>
      <App />
    </UserStore>
  </Router>,

  document.getElementById("root")
);

// ReactDOM.render(
//   <FirebaseContext.Provider value={new Firebase()}>
//     <Router>
//       <UserStore>
//         <App />
//       </UserStore>
//     </Router>
//   </FirebaseContext.Provider>,

//   document.getElementById("root")
// );

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
