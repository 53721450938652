import React from "react";

import css from "./style.module.css";
import Logo from "../Logo";
import Menu from "../MenuBuilder/Menu";
import HamburgerMenu from "../MenuBuilder/HamburgerMenu";

const Toolbar = props => (
  <header className={css.Toolbar}>
    <div className={css.Main}>
      <HamburgerMenu toggleSideBar={props.toggleSideBar} />

      <Logo />

      <nav className={css.HideOnMobile}>
        <Menu />
      </nav>
    </div>
  </header>
);

export default Toolbar;
