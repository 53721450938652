export const orderConstants = {
  UPLOAD_REQUEST: "PHOTO_UPLOAD_REQUEST",
  UPLOAD_SUCCESS: "PHOTO_UPLOAD_SUCCESS",
  UPLOAD_LOADING: "PHOTO_UPLOAD_LOADING",
  UPLOAD_FAILURE: "PHOTO_UPLOAD_FAILURE",
  //qpay unshih heseg
  QPAY_REQUEST: "QPAY_REQUEST",
  QPAY_SUCCESS: "QPAY_SUCCESS",
  QPAY_FAILURE: "QPAY_FAILURE",

  //order download
  ORDER_DOWN_REQUEST: "ORDER_DOWNLOAD_REQUEST",
  ORDER_DOWN_SUCCESS: "ORDER_DOWNLOAD_SUCCESS",
  ORDER_DOWN_FAILURE: "ORDER_DOWNLOAD_FAILURE",

  //PAY CHECK
  QPAY_PAYCHECK_REQUEST: "QPAY_PAYCHECK_REQUEST",
  QPAY_PAYCHECK_SUCCESS: "QPAY_PAYCHECK_SUCCESS",
  QPAY_PAYCHECK_FAILURE: "QPAY_PAYCHECK_FAILURE"
};
