import axios from "../../axios";

export const userService = {
  login,
  logout,
  register,
  getAll,
  getById,
  update,
  delete: _delete,
};
function register(userData) {
  return axios.post("/users/create", userData).then((user) => {
    return user.data;
  });
}
function login(userData) {
  return axios.post("/users/login", userData).then((user) => {
    return user.data;
  });
}

function logout() {
  // remove user from local storage to log user out
  localStorage.removeItem("token");
}

function getAll() {
  const requestOptions = {
    method: "GET",
  };

  return fetch(`users`, requestOptions).then(handleResponse);
}

function getById(id) {
  const requestOptions = {
    method: "GET",
  };

  return fetch(`users/${id}`, requestOptions).then(handleResponse);
}

function update(user) {
  const requestOptions = {
    method: "PUT",
    //headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(user),
  };

  return fetch(`users/${user.id}`, requestOptions).then(handleResponse);
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
  const requestOptions = {
    method: "DELETE",
    //headers: authHeader(),
  };

  return fetch(`users/${id}`, requestOptions).then(handleResponse);
}

function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        logout();
        //location.reload(true);
      }

      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
}
