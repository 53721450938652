import { orderConstants } from "../_constants";
const initialState = {
  loaded: null,
  uploading: false
};

export function orderUpload(state = initialState, action) {
  switch (action.type) {
    case orderConstants.UPLOAD_REQUEST:
      return {
        loaded: action.loading,
        uploading: true
      };
    case orderConstants.UPLOAD_SUCCESS:
      return {
        loaded: true,
        uploading: false,
        orderNumber: action.orderNumber
      };
    case orderConstants.UPLOAD_FAILURE:
      return {
        error: action.error
      };

    //ORDER DOWNLOAD
    case orderConstants.ORDER_DOWN_REQUEST:
      return {
        orderDownload: true
      };
    case orderConstants.ORDER_DOWN_SUCCESS:
      return {
        orderDownload: false,
        orderList: action.orderList
      };
    case orderConstants.ORDER_DOWN_FAILURE:
      return {
        error: action.error
      };

    //qpay actions
    case orderConstants.QPAY_REQUEST:
      return {
        newTotalPrice: 0,
        orderNumber: state.orderNumber,
        qpayLoading: true
      };

    case orderConstants.QPAY_SUCCESS:
      return {
        orderNumber: action.orderId,
        qpayData: action.qpayData,
        newTotalPrice: action.price,
        qpayLoading: false,
        qpaySuccess: true
      };
    case orderConstants.QPAY_FAILURE:
      return {
        error: action.error
      };

    default:
      return state;
  }
}
