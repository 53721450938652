import React, { useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import axios from "../../axios";
import PhotoContext from "../photoContext";
import { orderService } from "./order.service";
const OrderContext = React.createContext();

const initialState = {
  loaded: [],
  uploading: false,
  message: null,
  error: null,
};

const qPayInitialState = {
  orderNumber: null,
  phoneNumber: null,
  qpayData: {},
  qpayLoading: false,
  qpaySuccess: false,
  message: null,
};

const orderDownloadInitial = {
  orderDownload: false,
  orderList: null,
  error: null,
};
export const OrderStore = (props) => {
  const photoContext = useContext(PhotoContext);
  const [order, setOrder] = useState(initialState);
  const [qPay, setQpay] = useState(qPayInitialState);
  const [orderList, setOrderList] = useState(orderDownloadInitial);
  
  let history = useHistory();
  
  const qPayPayment = async(orderId, price, phone, shop_id) => {
    setQpay({
      ...qPay,
      qpayLoading: true,
    });
   
    if ((orderId, price, phone)) {
      return new Promise(()=> {
        orderService
          .qPay(orderId, price, phone, shop_id)
          .then((result) => {
            setQpay({
              ...qPay,
              orderNumber: orderId,
              qpayData: result,
              qpayLoading: false,
              qpaySuccess: true,
            });
            history.push("/cart/orders");
          })
          .catch((error) => {
            setOrder({ ...qPay, message: error });
            return false;
          })
      })
    }
 
  };

  //order tatah
  const orderDownload = () => {
    const phoneNumber = qPay.phoneNumber;
    const orderNumber = qPay.orderNumber;
    if (phoneNumber) {
      const result = JSON.parse(localStorage.getItem("qpayList"));
      setQpay({
        ...qPay,
        orderNumber: orderNumber,
        qpayData: result,
        qpayLoading: false,
        qpaySuccess: true,
      });
    }
  };

  const clearOrders = () => {
    setOrder(initialState);
    setQpay(qPayInitialState);
    setOrderList(orderDownloadInitial);
  };

  const getOrderNumber = () => {
    let orderNumber = "";
    if (!qPay.orderNumber) {
      let newNumber = new Date().getTime().toString().substr(-8);
      setQpay({
        ...qPay,
        orderNumber: newNumber,
      });
      orderNumber = newNumber;
    } else {
      orderNumber = qPay.orderNumber;
    }
    return orderNumber;
  };
  
  const getPhoneNumber = (pNumber) => {
    if (!qPay.phoneNumber) {
      qPay.phoneNumber = pNumber;
      setQpay({
        ...qPay,
        phoneNumber: pNumber,
      });
    }
    return qPay.phoneNumber;
  };
  
  return (
    <OrderContext.Provider
      value={{
        order,
        qPay,
        orderList,
        orderDownload,
        clearOrders,
        qPayPayment,
        getOrderNumber,
        getPhoneNumber,
      }}
    >
      {props.children}
    </OrderContext.Provider>
  );
};
export default OrderContext;
