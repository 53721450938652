import React from "react";

import css from "./style.module.css";

import apple from "../../assets/images/apple.png";
import android from "../../assets/images/android.png";
import konica from "../../assets/images/logo.png";
const Footer = () => {
  return (
    <div className={css.Main}>
      <div className={css.Copyright}>
        <img src={konica} className={css.appCompanyLogo} alt="Konica Minolta" />
        © 2020 Konica Minolta
      </div>

      <div>
        <a href="https://apps.apple.com/us/app/my-photo-mongolia/id1499928299?ls=1">
          <img src={apple} className={css.appLogo} alt="apple app download" />
        </a>
        <a href="https://play.google.com/store/apps/details?id=com.konica.myphoto">
          <img
            src={android}
            className={css.appLogo}
            alt="android app download"
          />
        </a>
      </div>
    </div>
  );
};

export default Footer;
