import React, { useState, useEffect, useContext } from "react";
import { withRouter } from "react-router-dom";
import UserContext from "../../context/userContext";
import css from "./style.module.css";
import Spinner from "../../components/General/Spinner";
import logo from "../../assets/images/logo.png";

const Register = (props) => {
  const userCtx = useContext(UserContext);
  const [phonenumber, setPhonenumber] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [firstname, setfirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [passwordHidden, setPasswordHidden] = useState(false);
  const [passwordConfirmHidden, setPasswordConfirmHidden] = useState(false);
  const [fieldValidationErrors, setFieldValidationErrors] = useState({});
  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitted(true);

    const user = {
      firstname,
      lastname,
      phonenumber,
      email,
      password: passwordConfirm,
      gender: "",
      usertype: "3",
      exponentpushtoken: "",
    };
    if (firstname && lastname && phonenumber && email && passwordConfirm) {
      userCtx.userRegister(user);
    }
  };

  useEffect(() => {
    if (userCtx.register.error) {
      alert(userCtx.register.error);
      props.history.push("/login");
    }
    if (userCtx.register.message) {
      alert(userCtx.register.message.message);
      props.history.push("/login");
    }
    // return () => {
    //   props.clear();
    // };
  }, [userCtx.register.error, userCtx.register.message]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    //alertnii medeelel tseverlej bna
    //    props.alertClear();
    if (name === "firstname") setfirstname(value);
    if (name === "lastname") setLastname(value);
    if (name === "phonenumber")
      setPhonenumber(() => validateField(name, value));
    if (name === "email") setEmail(() => validateField(name, value));
    if (name === "password") setPassword(() => validateField(name, value));
    if (name === "passwordConfirm")
      setPasswordConfirm(() => validateField(name, value));
  };

  const showHide = (fieldName) => {
    if (fieldName === "password") setPasswordHidden(!passwordHidden);
    if (fieldName === "passwordConfirm")
      setPasswordConfirmHidden(!passwordConfirmHidden);
  };

  const validateField = (fieldName, value) => {
    let emailValid = "";
    let passwordValid = "";
    let passwordConfirmValid = "";
    let phonenumberValid = "";
    switch (fieldName) {
      case "email":
        emailValid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
        fieldValidationErrors.email = emailValid ? "" : " буруу байна.";
        break;
      case "password":
        passwordValid = value.length >= 6 && value;
        fieldValidationErrors.password = passwordValid
          ? ""
          : " Багадаа 6 н тэмдэгт";
        break;
      case "passwordConfirm":
        passwordConfirmValid = password === value && value;
        fieldValidationErrors.passwordConfirm = passwordConfirmValid
          ? ""
          : " Буруу байна";
        break;
      case "phonenumber":
        phonenumberValid = value.length === 8 && value;
        fieldValidationErrors.phonenumber = phonenumberValid ? "" : "8 оронтой";

        break;
    }

    if (emailValid) setEmail(emailValid.input);
    if (passwordValid) setPassword(passwordValid);
    if (passwordConfirmValid) setPasswordConfirm(passwordConfirmValid);
    if (phonenumberValid) setPhonenumber(phonenumberValid);
    setFieldValidationErrors(fieldValidationErrors);
  };

  return (
    <div className={css.Main}>
      {userCtx.register.registerLoading && (
        <div className={css.Loading}>
          <Spinner />
        </div>
      )}
      <form name="form" onSubmit={handleSubmit}>
        <div className={css.field}>
          <input
            type="text"
            name="lastname"
            id="lastname"
            placeholder=" "
            onChange={handleChange}
            className={lastname ? css.input : null}
          />
          <label
            className={
              submitted ? (lastname ? css.label : css.error) : css.label
            }
            htmlFor="lastname"
          >
            <div className={props.type === "alert-danger" ? css.error : null}>
              {props.type === "alert-danger"
                ? "ОВОГ БУРУУ ОРУУЛСАН БАЙНА"
                : "ОВОГ:"}
            </div>
          </label>
        </div>

        <div className={css.field}>
          <input
            type="text"
            name="firstname"
            id="firstname"
            placeholder=" "
            onChange={handleChange}
            className={firstname ? css.input : null}
          />
          <label
            className={
              submitted ? (firstname ? css.label : css.error) : css.label
            }
            htmlFor="firstname"
          >
            <div className={props.type === "alert-danger" ? css.error : null}>
              {props.type === "alert-danger"
                ? "НЭР БУРУУ ОРУУЛСАН БАЙНА"
                : "НЭР:"}{" "}
            </div>
          </label>
        </div>

        <div className={css.field}>
          <input
            type="number"
            name="phonenumber"
            id="phonenumber"
            placeholder=" "
            onChange={handleChange}
            className={phonenumber ? css.input : null}
          />
          <label
            className={
              submitted ? (phonenumber ? css.label : css.error) : css.label
            }
            htmlFor="phonenumber"
          >
            <div className={props.type === "alert-danger" ? css.error : null}>
              {props.type === "alert-danger"
                ? "УТАСНЫ ДУГААР БУРУУ БАЙНА"
                : "УТАС:"}
              <span style={{ color: "red" }}>
                {fieldValidationErrors.phonenumber}
              </span>
            </div>
          </label>
        </div>
        <div className={css.field}>
          <input
            type="text"
            name="email"
            id="email"
            placeholder=" "
            onChange={handleChange}
            className={email ? css.input : null}
          />
          <label
            className={submitted ? (email ? css.label : css.error) : css.label}
            htmlFor="email"
          >
            <div className={props.type === "alert-danger" ? css.error : null}>
              {props.type === "alert-danger"
                ? "И-МЭЙЛ ХАЯГ БУРУУ БАЙНА"
                : "И-МЭЙЛ:"}
              <span style={{ color: "red" }}>
                {fieldValidationErrors.email && fieldValidationErrors.email}
              </span>
            </div>
          </label>
        </div>

        <div className={css.field}>
          <input
            type={!passwordHidden ? "password" : "text"}
            name="password"
            id="password"
            placeholder=" "
            onChange={handleChange}
            className={password ? css.input : null}
          />
          <span
            className={css.passwordShow}
            onClick={() => showHide("password")}
          >
            {!passwordHidden ? "харах" : "нуух"}
          </span>
          <label
            className={
              submitted ? (password ? css.label : css.error) : css.label
            }
            htmlFor="password"
          >
            <div className={props.type === "alert-danger" ? css.error : null}>
              {props.type === "alert-danger"
                ? "НУУЦ ҮГ БУРУУ БАЙНА"
                : "НУУЦ ҮГ:"}
              <span style={{ color: "red" }}>
                {fieldValidationErrors.password &&
                  fieldValidationErrors.password}
              </span>
            </div>
          </label>
        </div>

        <div className={css.field}>
          <input
            type={!passwordConfirmHidden ? "password" : "text"}
            name="passwordConfirm"
            id="passwordConfirm"
            placeholder=" "
            onChange={handleChange}
            className={passwordConfirm ? css.input : null}
          />
          <span
            className={css.passwordShow}
            onClick={() => showHide("passwordConfirm")}
          >
            {!passwordConfirmHidden ? "харах" : "нуух"}
          </span>
          <label
            className={
              submitted ? (passwordConfirm ? css.label : css.error) : css.label
            }
            htmlFor="passwordConfirm"
          >
            <div className={props.type === "alert-danger" ? css.error : null}>
              {props.type === "alert-danger"
                ? "НУУЦ ҮГ БУРУУ БАЙНА"
                : "НУУЦ ҮГ ДАХИН ОРУУЛНА УУ:"}
              <span style={{ color: "red" }}>
                {fieldValidationErrors.passwordConfirm &&
                  fieldValidationErrors.passwordConfirm}
              </span>
            </div>
          </label>
        </div>
        <div className={css.LoginButton}>
          <button type="submit">БҮРТГҮҮЛЭХ</button>
        </div>
      </form>
    </div>
  );
};

export default withRouter(Register);
