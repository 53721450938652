import axios from "../../axios";
import axiosRealtime from "../../axios-firebase-realtime";
export const orderService = {
  qPay,
  // orderDownload,
};
function qPay(orderId, price, phone, shop_id) {
  const fieldData = {
    order: orderId,
    price: parseInt(price),
    phone: phone,
    shop_id: shop_id,
    payment: 0,
  };
  return axios
    .post("/api/getQpayList", fieldData)
    .then((resultQpayList) => {
      //saveOrder(fieldData);
      //localStorage.setItem("qpayList", JSON.stringify(qpayList.data.data));
      return resultQpayList.data;
    })
    .catch((error) => {
      return error;
    });
}

function saveOrder(newOrder) {
  // Firebase ruu hadgalna
  axiosRealtime
    .post(`/orders.json`, newOrder)
    .then((response) => {
    console.log(response);
      //console.log(response);
    })
    .catch((error) => {
      //console.log("error", error);
    });
}

// function orderDownload(phone) {
//   let formData = new FormData();
//   formData.append("file", "pay");
//   formData.append("type", "order_list");
//   formData.append("token", "konica-token");
//   formData.append("phone", phone);
//   return axios
//     .post("/switch.php", formData)
//     .then((orderList) => {
//       return orderList.data;
//     })
//     .catch((error) => {
//       return error;
//     });
// }
