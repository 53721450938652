import { userConstants } from "../_constants";

let token = localStorage.getItem("token");
let username = localStorage.getItem("username");
const initialState = token
  ? { loggedIn: true, token, username }
  : { loggedIn: false, loading: false };

export function authentication(state = initialState, action) {
  switch (action.type) {
    case userConstants.LOGIN_REQUEST:
      return {
        loggedIn: false,
        loading: true,
      };
    case userConstants.LOGIN_SUCCESS:
      return {
        loggedIn: true,
        loading: false,
        token: action.token,
        username: action.username,
      };
    case userConstants.LOGIN_FAILURE:
      return { loading: false, error: action.error };
    case userConstants.LOGOUT:
      return { loggedIn: false };
    default:
      return state;
  }
}
