import React, { Component } from "react";
import { withRouter, Route, Link } from "react-router-dom";
import { connect } from "react-redux";
import { Editor } from "@tinymce/tinymce-react";

import Spinner from "../../components/General/Spinner";
import axios from "../../axios";

import css from "./style.module.css";
class OurTerms extends Component {
  state = {
    content: "",
    newsList: [],
    isLoading: false
  };

  fetchUsers = () => {
    let formData = new FormData();
    formData.append("file", "news");
    formData.append("type", "terms");
    return axios
      .post("/switch.php", formData)
      .then(response => {
        if (response.data.success === 1) {
          this.setState({
            newsList: response.data.list.reverse()
          });
          this.setState({ isLoading: false });
        }
      })
      .catch(error => {
        alert("Сүлжээ салсан байна. Интернэт холболтоо шалгана уу?");
      });
  };

  UNSAFE_componentWillMount() {
    this.setState({ isLoading: true });
    this.fetchUsers();
  }

  saveToDataBase = id => {
    this.setState({ isLoading: true });
    if (this.state.content) {
      let formData = new FormData();
      formData.append("file", "news");
      formData.append("type", "edit_terms");
      formData.append("id", id);
      formData.append("content", this.state.content);
      return axios
        .post("/switch.php", formData)
        .then(response => {
          if (response.data.success === 2) {
            this.setState({ content: "" });
            this.setState({ isLoading: false });
            this.props.history.push("/terms");
          }
        })
        .catch(error => {
          alert("Сүлжээ салсан байна. Интернэт холболтоо шалгана уу?");
        });
    } else {
      return alert("Өөрчлөлт хийгдээгүй байна.");
    }
  };

  handleEditorChange = e => {
    this.setState({ content: e });
  };

  render() {
    let isAdmin: "";
    if (this.props.user) {
      isAdmin = Object.entries(this.props.user).map(el => {
        return el[1].isAdmin.toString();
      });
    }

    if (this.state.isLoading) {
      return (
        <div className={css.Loading}>
          <Spinner />
        </div>
      );
    }

    return (
      <div className={css.Main}>
        <Route
          exact
          path="/terms/"
          render={({ match }) => {
            return (
              <Medee
                id={match.params.id}
                content={this.state.newsList}
                isAdmin={isAdmin}
              />
            );
          }}
        />
        <Route path="/terms/edit/:id">
          <Edit
            //id={match.params.id}
            content={this.state.newsList}
            handleEditorChange={this.handleEditorChange}
            saveToDataBase={this.saveToDataBase}
          />
        </Route>
      </div>
    );
  }
}
const mapStateToProps = state => {
  const { user } = state.authentication;
  return { user };
};

export default connect(mapStateToProps)(withRouter(OurTerms));

function Edit(props) {
  let newsList = props.content.map(
    ({ id, name, date, content, pic }, index) => {
      // let link = "/terms/" + id;
      return (
        <div key={index} className={css.Main2}>
          <form id="form-data" method="post">
            <div className={css.Top}>
              <div className={css.Title}>{name}</div>
              <div>
                <span
                  className={css.Button}
                  onClick={() => props.saveToDataBase(id)}
                >
                  ХАДГАЛАХ
                </span>
                <Link to={"/terms/"} className={css.Button}>
                  БУЦАХ
                </Link>
              </div>
            </div>
            <Editor
              apiKey="x6xxhmv5ij7yqzr49nup29s0dsbp1zjlms4ajjzlw4915pri"
              disabled={false}
              id="editTerms"
              initialValue={content}
              init={{
                height: 500,
                menubar: true,
                plugins: [
                  "advlist autolink lists link image charmap print preview anchor",
                  "searchreplace visualblocks code fullscreen",
                  "insertdatetime media table paste code help wordcount"
                ],
                toolbar:
                  "undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help"
              }}
              onEditorChange={props.handleEditorChange}
            />
          </form>
        </div>
      );
    }
  );

  return newsList;
}

function Medee(props) {
  let newsList = props.content.map(
    ({ id, name, date, content, pic }, index) => {
      let link = "/terms/edit/" + id;

      return (
        <div key={id} className={css.list}>
          <div className={css.Top}>
            <div className={css.Title}>{name}</div>
            {props.isAdmin ? (
              <div>
                <Link to={link} className={css.Button}>
                  ЗАСАХ
                </Link>
              </div>
            ) : null}
          </div>
          <div className={css.Image}>
            {pic ? <img src={pic} alt={name} /> : null}
          </div>

          <div
            className={css.Content}
            dangerouslySetInnerHTML={{ __html: content }}
          ></div>
        </div>
      );
    }
  );
  return <div className={css.Main2}>{newsList}</div>;
}
