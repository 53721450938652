import React, { useContext, useEffect } from "react";
import MenuContext from "../../../context/menuContext";

import css from "./style.module.css";
import MenuItem from "../MenuItem";

import Spinner from "../../General/Spinner";

const Menu = (props) => {
  const menuCtx = useContext(MenuContext);
   //zurgiin hemjee serverees tataj bna
  useEffect(() => {
    let isCancelled = menuCtx.state.getDatabaseMenu["downloadMenu"];
    const runAsync = async () => {
      try {
        if (!isCancelled) {
          menuCtx.getMenu();
        }
      } catch (e) {
        if (!isCancelled) {
          throw e;
        }
      }
    };

    runAsync();

    return () => {
      isCancelled = true;
    };
  }, []);
  
  return (
    <div>
    {menuCtx.state.getDatabaseMenu["downloadMenu"] ?
      <ul className={css.Menu}>
        {
             Object.keys(menuCtx.state.menuList).map((el) => {
            return(
              <MenuItem link={menuCtx.state.menuList[el].category_id ? `/news/${menuCtx.state.menuList[el].category_id}` : menuCtx.state.menuList[el].path} key={el}>{menuCtx.state.menuList[el].name}</MenuItem>
            )}
          )
        }
        
      </ul>
      : <ul className={css.Menu}>
        <div className="spinner-border spinner-border-sm" role="status" aria-hidden="true">
        </div>
      </ul>}
    </div>
  );
};

export default Menu;
